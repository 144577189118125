import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppState } from "../../../redux/store";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { fontColor } from "../../../constants/ColorSets";
import { Fonts } from "../../../constants/AppEnums";
import { RuleInstance } from "../../../types/models/RuleInstance";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { splitSearchText } from "../../../utils/StringUtils";
import { RoleState } from "../../../types/models/RoleState";

interface RuleSectionProps {
    pageLocations: string[] | null;
    pageRoles: string[] | null,
    contentRevisionId: string,
    rule: RuleInstance,
    element: ReactElement,
    index: number,
    isActive: boolean,
    handleClickSection: (index: number) => void,
    forceExpandByRole: boolean,
    hideTitle: boolean,
    isInformalTable: boolean
    locationMetaData: string | undefined
}

const RuleSection = (prop: RuleSectionProps) => {
    const [isAccessibleByRole, setIsAccessibleByRole] = useState<boolean>(false);
    const [isAccessibleByLocation, setIsAccessibleByLocation] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(true);
    const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [isZoomed, setIsZoomed] = useState<boolean>(false);
    const [clickedImage, setClickedImage] = useState<{alt: string, src: string} | null>(null);
    const [sectionElement, setSectionElement] = useState<ReactElement>(prop.element);
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const roleState = useSelector<AppState, RoleState>((state) => state.role);
    const [highlightedContent, setHighlightedContent] = useState<string>("");

      const imageStyles = {
        cursor: isZoomed ? "zoom-out" : "zoom-in",
      }

    useEffect(() => {
        const role = ruleUnitState.selectedRoles?.some(selectedRole => prop.pageRoles?.some(pageRole => pageRole == selectedRole)) ?? false;
        setIsAccessibleByRole(role);
    }, [ruleUnitState.selectedRoles])

    useEffect(() => {
        const selectedLocations = ruleUnitState.selectedLocations?.map((location) => location.split(".")[0]);
        const locations = prop.pageLocations?.map((location) => location.split("-")[0]);
        if(locations) {
            const location = selectedLocations?.some((selectedLocation) => prop.pageLocations?.some((pageLocation) => pageLocation.includes(selectedLocation))) ?? false;
            setIsAccessibleByLocation(location);
        }
    }, [ruleUnitState.selectedLocations])

    useEffect(() => {
        if(ruleUnitState.searchText && ruleUnitState.searchText.replace(/"/g, '').length > 1) {
            const html = ReactDOMServer.renderToString(prop.element);
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
        
            const highlightTextNodes = (node: Node) => {
              if (node.nodeType === Node.TEXT_NODE) {
                const text = node.textContent || "";
                if (ruleUnitState.searchText?.trim()) {
                    const searchWords = splitSearchText(ruleUnitState.searchText);
                    const escapedSearchWords = searchWords.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
                
                    const regex = new RegExp(`(${escapedSearchWords.join('|')})`, 'gi');
                    const replaceChunk = (matchedText: string) => `<b style="background-color: ${fontColor.searchedTextBackground};">${matchedText}</b>`;
                    const highlightedContent = text.replace(regex, (match) => replaceChunk(match));
            
                    const span = document.createElement("span");
                    span.innerHTML = highlightedContent;

                    (node as Element).replaceWith(...span.childNodes);
                }
              } else {
                node.childNodes.forEach(highlightTextNodes);
              }
            };
        
            doc.body.childNodes.forEach(highlightTextNodes);
            setHighlightedContent(doc.body.innerHTML);
        }
        else {
            setHighlightedContent("");
            setSectionElement(prop.element);
        }
      }, [ruleUnitState.searchText]); 

    useEffect(() => {
        // change expanded status by top icon button
        if (!isAccessibleByRole && !isAccessibleByLocation) {
            setExpanded(prop.forceExpandByRole);
        }
    }, [prop.forceExpandByRole])

    const getContentElement = () => {
        return (
            <>
            <Modal
                open={imageModalOpen}
                onClose={() => {
                    setIsZoomed(false);
                    setImageModalOpen(false)
                    }
                }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
            >
                <Fade in={imageModalOpen}>
                    <div className="image-modal">
                        <TransformWrapper
                            smooth
                            minScale={0.7}
                            initialScale={1}
                            initialPositionX={1}
                            initialPositionY={1}>
                            <TransformComponent>
                                <img src={clickedImage?.src} alt={clickedImage?.alt} style={imageStyles} />
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                </Fade>
            </Modal>
        <Box
            onClick={(e) => {
                const clickedSection = e.target as HTMLElement;
                let selectedIndex = prop.index;
                if (prop.isInformalTable) {
                    // the audio file for informal table is divided by row, so find their index using text
                    selectedIndex = prop.rule.Paragraphs.findIndex(paragraph => paragraph.TextContent.includes((e.target as HTMLElement).textContent?.trim() ?? ""))
                }
                if (clickedSection.outerHTML.toString().includes("<img")) {
                    //set boolean to true to open a modal and get the image to display
                    const alt = Object.values(clickedSection)[1].alt;
                    const src = Object.values(clickedSection)[1].src;
                    setClickedImage({alt, src});
                    setImageModalOpen(true);
                }
                else if(clickedSection.outerHTML.toString().includes("<a href")) {
                    return;
                }
                else {
                    // Stop auto playing if it's started
                    prop.handleClickSection(selectedIndex);
                }
            }}
            sx={{
                cursor: "pointer",
                border: "1px solid " + (isAccessibleByRole || isAccessibleByLocation ? fontColor.roleAccessibleBackground : "white"),
                position: "relative",
                paddingX: 1,
                borderRadius: 0,
                background: prop.isActive ? fontColor.orangeHoverBackground : "transparent",
                "&:hover": {
                    border: "1px solid " + fontColor.orangeBackground,
                    ".playerContainer": {
                        display: "flex",
                        alignContent: "flex-end",
                        justifyContent: "flex-end",
                        borderRadius: 2,
                        position: "absolute",
                        right: 1,
                        bottom: 0,
                    }
                },
                ".figure .title": {
                    display: "block"
                },
                ".title": {
                    display: prop.hideTitle ? "none" : "block"
                }
            }}
            key={prop.rule.RuleInstanceId + prop.index}>
            {highlightedContent.length > 0 ? <div dangerouslySetInnerHTML={{ __html: highlightedContent }}></div> : sectionElement}
        </Box>
        </>
        );
    }

    const highlightText = (text: string, searchText: string) => {
        if (!searchText) return text;
        searchText = searchText.replace(/"/g, '');
        const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
        return parts.map((part, index) => 
            part.toLowerCase() === searchText.toLowerCase() ? <b style={{ backgroundColor: `${fontColor.searchedTextBackground}` }} key={index}>{part}</b> : part
        );
    };

    const getRoleDisplayName = (roles: string[]) => {
        const knownRoles = roleState.knownRoles?.roles;
        const displayNames = knownRoles?.filter(role => !role.RoleId.startsWith("sub_"))
            .filter((role) => roles.includes(role.RoleId))
            .map(role => highlightText(role.DisplayName, ruleUnitState.searchText ?? ""));

            return displayNames
        ? displayNames.flatMap((item, index) => (index > 0 ? [", ", item] : [item]))
        : null;
    }

    const getLocationDisplayName = (locations: string[]) => {
        const displayNames = locations.map((location) => highlightText(`LNI ${location}`, ruleUnitState.searchText ?? ""));

        return displayNames.flatMap((item, index) => (index > 0 ? [", ", item] : [item]));
    }

    if (prop.pageRoles && prop.pageRoles.length > 0 && prop.pageLocations && prop.pageLocations.length > 0) {
        return (
            <Accordion
                disableGutters={true}
                sx={{
                    position: "relative",
                    boxShadow: "none",
                    "&:last-of-type": {
                    },
                }}
                key={prop.rule.RuleInstanceId + prop.index}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}>
                <AccordionSummary
                    sx={{
                        padding: "0",
                        margin: "0",
                        backgroundColor: isAccessibleByRole && isAccessibleByLocation ? fontColor.roleAccessibleBackground : fontColor.grayBackground,
                        "& .MuiSvgIcon-root": {
                            color: fontColor.orangeTitle,
                        },
                        "&.Mui-expanded": {
                            backgroundColor: isAccessibleByRole && isAccessibleByLocation ? fontColor.roleAccessibleBackground : "white"
                        },

                    }}
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: Fonts.SEMI_BOLD, ml: 1 }}> {getLocationDisplayName(prop.pageLocations)} & {getRoleDisplayName(prop.pageRoles)}</Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: "0",
                        margin: "0",
                        backgroundColor: isAccessibleByRole && isAccessibleByLocation ? fontColor.roleAccessibleBackground : "white",
                    }}>
                    {getContentElement()}
                </AccordionDetails>
            </Accordion>
        );
    }
    else if (prop.pageRoles && prop.pageRoles.length > 0 || prop.pageLocations && prop.pageLocations.length > 0) {
        return (
            <Accordion
                disableGutters={true}
                sx={{
                    position: "relative",
                    boxShadow: "none",
                    "&:last-of-type": {
                    },
                }}
                key={prop.rule.RuleInstanceId + prop.index}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}>
                <AccordionSummary
                    sx={{
                        padding: "0",
                        margin: "0",
                        backgroundColor: isAccessibleByRole || isAccessibleByLocation ? fontColor.roleAccessibleBackground : fontColor.grayBackground,
                        "& .MuiSvgIcon-root": {
                            color: fontColor.orangeTitle,
                        },
                        "&.Mui-expanded": {
                            backgroundColor: isAccessibleByRole || isAccessibleByLocation ? fontColor.roleAccessibleBackground : "white"
                        },

                    }}
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: Fonts.SEMI_BOLD, ml: 1 }}> {getLocationDisplayName(prop.pageLocations ?? [])} {getRoleDisplayName(prop.pageRoles ?? [])}</Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: "0",
                        margin: "0",
                        backgroundColor: isAccessibleByRole && isAccessibleByLocation ? fontColor.roleAccessibleBackground : "white",
                    }}>
                    {getContentElement()}
                </AccordionDetails>
            </Accordion>
        );
    }
    else {
        return (getContentElement());
    }
};

export default RuleSection;
