import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, ClickAwayListener, Collapse, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, TableCell, TableRow } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { deleteTeam, getOrgTeams, showMessage, updateTeam } from "../../../redux/actions";
import { AppState } from "../../../redux/store";
import { TeamMembersState } from "../../../types/models/TeamMembersState";
import { VirtualTeam } from "../../../types/models/Team";
import { theme } from "../../../themes/theme";
import TeamMembersTable from "./TeamMembersTable";
import AppConfirmDialog from "../../../components/AppBase/AppConfirmDialog";
import EditUserDialog from "../EditUser";
import { UpdateTeamRequest } from "../../../types/models/UpdateTeamRequest";
import { RISUser } from "../../../types/models/RISUser";
import { canUpdateTeam, getHighlightedElement } from "../TeamUtils";
import PlusMoreLabel from "../../../components/TableItems/PlusMoreLabel";
import { AuthUserInfo } from "../../../types/models/AuthUserInfo";
import { getAuthUserInfoFromToken, isCurrentAccountAdmin } from "../../../components/AppBase/AppAuthProvider";

interface TeamTableRowProp {
    team: VirtualTeam,
    onOpenEditDialog: (team: VirtualTeam) => void
}

export const AddUserType = ["LEADER", "MEMBER"] as const;

const TeamTableRow: React.FC<TeamTableRowProp> = (prop) => {

    const DeleteTargetType = ["TEAM", "LEADER", "MEMBER"] as const;

    const dispatch = useDispatch();
    const teamState = useSelector<AppState, TeamMembersState>((state) => state.teamMembers);

    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<AuthUserInfo | undefined>();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState<boolean>(false);
    const [deleteUserId, setDeleteUserId] = useState<string>();
    const [deleteTarget, setDeleteTarget] = useState<(typeof DeleteTargetType)[number]>();

    const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);
    const [addUserMode, setAddUserMode] = useState<(typeof AddUserType)[number]>("LEADER");
    const [openZeroUserAlert, setOpenZeroUserAlert] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDeleteSelectedTeam = () => {
        setOpenDeleteConfirmDialog(false);
        setIsLoading(true);
        dispatch(deleteTeam(prop.team.TeamId, (result) => {
            if (result) {
                dispatch(getOrgTeams(() => {
                    setIsLoading(false);
                    dispatch(showMessage("The team has been deleted."));
                }));
            }
            else {
                setIsLoading(false);
            }
        }));
    };

    const createUpdateTeamRequest = (leaderIds: string[], memberIds: string[]) => {
        const updateTeamRequest: UpdateTeamRequest = {
            TeamId: prop.team.TeamId,
            DisplayName: prop.team.DisplayName,
            Leaders: leaderIds,
            Members: memberIds
        };
        return updateTeamRequest;
    };

    const handleDeleteSelectedLeader = () => {
        if (!deleteUserId) return;
        const leaderIds = prop.team.Members
            .filter(member => member.TeamLeader === true && member.UserId !== deleteUserId)
            .map(leader => leader.UserId);
        const memberIds = prop.team.Members
            .filter(member => member.TeamLeader === false)
            .map(member => member.UserId);

        setIsLoading(true);
        dispatch(updateTeam(createUpdateTeamRequest(leaderIds, memberIds), (result) => {
            if (result) {
                setOpenDeleteConfirmDialog(false);
                dispatch(getOrgTeams(() => {
                    dispatch(showMessage("The leader has been deleted."));
                    setIsLoading(false);
                }));
            }
            else {
                setIsLoading(false);
            }
        }));
    };

    const handleDeleteSelectedMember = () => {
        if (!deleteUserId) return;
        const leaderIds = prop.team.Members
            .filter(member => member.TeamLeader === true)
            .map(leader => leader.UserId);
        const memberIds = prop.team.Members
            .filter(member => member.TeamLeader === false && member.UserId !== deleteUserId)
            .map(member => member.UserId);

        setIsLoading(true);
        dispatch(updateTeam(createUpdateTeamRequest(leaderIds, memberIds), (result) => {
            if (result) {
                setOpenDeleteConfirmDialog(false);
                dispatch(getOrgTeams(() => {
                    dispatch(showMessage("The member has been deleted."));
                    setIsLoading(false);
                }));
            }
            else {
                setIsLoading(false);
            }
        }));
    };

    const getDeleteConfirmMessage = () => {
        switch (deleteTarget) {
            case "TEAM":
                return `Are you sure you want to delete "${prop.team.DisplayName}" ?`;
            case "LEADER":
                return `Are you sure you want to delete the leader?`;
            case "MEMBER":
                return `Are you sure you want to delete the member?`;
        }
    };

    const getDeleteConfirmTitle = () => {
        switch (deleteTarget) {
            case "TEAM":
                return "Delete Team";
            case "LEADER":
                return "Delete Leader";
            case "MEMBER":
                return "Delete Member";
        }
    };

    const callDeleteAction = () => {
        switch (deleteTarget) {
            case "TEAM":
                handleDeleteSelectedTeam();
                break;
            case "LEADER":
                handleDeleteSelectedLeader();
                break;
            case "MEMBER":
                handleDeleteSelectedMember();
                break;
            default:
                break;
        }
    };

    const handleUpdateUsers = (mode: (typeof AddUserType)[number], users: RISUser[]) => {
        setOpenAddUserDialog(false);
        if (users.length === 0) {
            setOpenZeroUserAlert(true);
        } else {
            let leaderIds: string[];
            let memberIds: string[];
            switch (mode) {
                case "LEADER":
                    leaderIds = users.filter(leader => leader.UserId).map(leader => leader.UserId ?? "");
                    memberIds = prop.team.Members
                        .filter(member => member.TeamLeader === false)
                        .map(member => member.UserId);
                    setIsLoading(true);
                    dispatch(updateTeam(createUpdateTeamRequest(leaderIds, memberIds), (result) => {
                        if (result) {
                            dispatch(getOrgTeams(() => {
                                dispatch(showMessage("The leaders have been updated."));
                                setIsLoading(false);
                            }));
                        }
                        else {
                            setIsLoading(false);
                        }
                    }));
                    break;
                case "MEMBER":
                    leaderIds = prop.team.Members
                        .filter(member => member.TeamLeader === true)
                        .map(member => member.UserId);
                    memberIds = users.filter(member => member.UserId).map(member => member.UserId ?? "");
                    setIsLoading(true);
                    dispatch(updateTeam(createUpdateTeamRequest(leaderIds, memberIds), (result) => {
                        if (result) {
                            dispatch(getOrgTeams(() => {
                                dispatch(showMessage("The members have been updated."));
                                setIsLoading(false);
                            }));
                        }
                        else {
                            setIsLoading(false);
                        }
                    }));
            }
        }
    };

    const createLeadersCell = () => {
        const leaderCount = (prop.team.Members?.filter(member => member.TeamLeader === true).length ?? 0);
        if (leaderCount > 2) {
            return (
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Box>{getHighlightedElement(prop.team.Members?.filter((member) => member.TeamLeader).map((leader) => leader.DisplayName ? leader.DisplayName : leader.Email).slice(0, 2).join(", "), teamState.orgTeamSearchText)}</Box>
                    <PlusMoreLabel moreCount={leaderCount - 2} />
                </Box>
            );
        } else {
            return getHighlightedElement(prop.team.Members?.filter(member => member.TeamLeader === true).map(leader => leader.DisplayName ? leader.DisplayName : leader.Email).join(", "), teamState.orgTeamSearchText);
        }
    };

    useEffect(() => {
        setIsAdmin(isCurrentAccountAdmin());
        setUserInfo(getAuthUserInfoFromToken());
    }, [])

    return (
        <React.Fragment>
            {isLoading && (
                <TableRow>
                    <Box sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <CircularProgress size="3rem" sx={{ color: theme.palette.primary.main }} />
                    </Box>
                </TableRow>
            )}
            <TableRow sx={{ "& .MuiTableCell-root": { borderBottom: "none" } }}>
                <TableCell sx={{ pr: 0 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {getHighlightedElement(prop.team.DisplayName, teamState.orgTeamSearchText)}
                </TableCell>
                <TableCell>{createLeadersCell()}</TableCell>
                <TableCell>{prop.team.Members?.filter(member => member.TeamLeader === false).length ?? 0}</TableCell>
                {/* <TableCell>{prop.team.StartDate}</TableCell>
                <TableCell>{prop.team.EndDate}</TableCell> */}
                <TableCell>{prop.team.OwnerEmail}</TableCell>
                <TableCell sx={{ px: 0 }}>
                    {userInfo &&                   
                    <IconButton
                        disabled={!canUpdateTeam(prop.team, isAdmin, userInfo?.Email ?? "")}
                        ref={anchorRef}
                        onClick={() => { setIsOpenMenu((prevOpen) => !prevOpen); }}>
                        <MoreVertIcon />
                    </IconButton>}
  
                    <Popper
                        open={isOpenMenu}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-end"
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}>
                                <Paper sx={{ backgroundColor: "white", zIndex: 9999 }}>
                                    <ClickAwayListener onClickAway={() => setIsOpenMenu(false)}>
                                        <MenuList
                                            autoFocusItem={isOpenMenu}>
                                            <MenuItem
                                                onClick={() => {
                                                    setIsOpenMenu(false);
                                                    prop.onOpenEditDialog(prop.team);
                                                }}>
                                                <EditOutlinedIcon />
                                                <Box sx={{ ml: 1 }}>Edit</Box>
                                            </MenuItem>
                                            <MenuItem
                                                sx={{ color: theme.palette.primary.main }}
                                                onClick={() => {
                                                    setIsOpenMenu(false);
                                                    setDeleteTarget("TEAM");
                                                    setOpenDeleteConfirmDialog(true);
                                                }}>
                                                <DeleteOutlineOutlinedIcon />
                                                <Box sx={{ ml: 1 }}>Delete</Box>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </TableCell>
            </TableRow >
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid container sx={{ width: "100%", mt: 0, mb: 2, "& .MuiGrid-item": { pl: 2 } }} columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}>
                            <Grid item xs={6} >
                                <TeamMembersTable
                                    team={prop.team}
                                    isLeader={true}
                                    onEdit={() => { setAddUserMode("LEADER"); setOpenAddUserDialog(true); }}
                                    onDelete={(userId) => {
                                        if (prop.team.Members?.filter(member => member.TeamLeader === true).length === 1) {
                                            setOpenZeroUserAlert(true);
                                        }
                                        else {
                                            setDeleteUserId(userId);
                                            setDeleteTarget("LEADER");
                                            setOpenDeleteConfirmDialog(true);
                                        }
                                    }}
                                    users={prop.team.Members.filter(member => member.TeamLeader === true) ?? []} />
                            </Grid>
                            <Grid item xs={6} sx={{ pt: { xs: 2, sm: 2, md: 0, lg: 0 } }}>
                                <TeamMembersTable
                                    team={prop.team}
                                    isLeader={false}
                                    onEdit={() => { setAddUserMode("MEMBER"); setOpenAddUserDialog(true); }}
                                    onDelete={(userId) => {
                                        if (prop.team.Members?.filter(member => member.TeamLeader === false).length === 1) {
                                            setOpenZeroUserAlert(true);
                                        }
                                        else {
                                            setDeleteUserId(userId);
                                            setDeleteTarget("MEMBER");
                                            setOpenDeleteConfirmDialog(true);
                                        }
                                    }
                                    }
                                    users={prop.team.Members.filter(member => member.TeamLeader === false) ?? []} />
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
            <EditUserDialog
                isOpen={openAddUserDialog}
                mode={addUserMode}
                setIsOpen={setOpenAddUserDialog}
                allUsers={teamState.users ?? []}
                currenUsers={prop.team.Members.filter(member => member.TeamLeader === (addUserMode === "LEADER")) ?? []}
                reservedUserIds={(prop.team.Members.filter(member => member.TeamLeader !== (addUserMode === "LEADER")) ?? []).map(member => member.UserId)}
                onSelectUsers={(users) => handleUpdateUsers(addUserMode, users)} />
            <AppConfirmDialog
                open={openDeleteConfirmDialog}
                onDeny={() => setOpenDeleteConfirmDialog(false)}
                onConfirm={() => callDeleteAction()}
                title={getDeleteConfirmMessage()}
                dialogTitle={getDeleteConfirmTitle()}
            ></AppConfirmDialog >
            <AppConfirmDialog
                open={openZeroUserAlert}
                disableDenyButton={true}
                confirmButtonTitle="OK"
                onConfirm={() => setOpenZeroUserAlert(false)}
                title="Can not delete all users."
                dialogTitle="Delete user"
            ></AppConfirmDialog >
        </React.Fragment >
    );
};

export default TeamTableRow;
