import React from "react";
import { fontColor } from "../../constants/ColorSets";
import { Box } from "@mui/material";
import _ from "lodash";
import { splitSearchText } from "../../utils/StringUtils";
import { VirtualTeam } from "../../types/models/Team";

export function getHighlightedElement(baseText: string, searchText: string | undefined) {
    if (searchText) {
        const searches = splitSearchText(searchText);
        const searchRegex = searches.map(search => _.escapeRegExp(search)).join("|");
        // Split text on highlight term, include term itself into parts, ignore case
        const parts = baseText?.split(new RegExp(`(${searchRegex})`, "gi")) ?? [];

        return parts.map((part, index) => (
            <React.Fragment key={index}>
                {searches.includes(part.toLowerCase()) ? (
                    <span style={{ backgroundColor: fontColor.searchedTextBackground }}>{part}</span>
                ) : (
                    part
                )}
            </React.Fragment>
        ));
    } else {
        return (<Box>{baseText}</Box>)
    }
}


export const canUpdateTeam = (team: VirtualTeam, isAdmin: boolean, userEmail: string) => {
    const isTeamOwner =
      userEmail.toLowerCase() === team.OwnerEmail?.toLowerCase();

      return isTeamOwner || isAdmin || false;
}