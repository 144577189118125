import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MessageIcon from '@mui/icons-material/Message';
import { theme } from "../../../themes/theme";
import { Acknowledgment } from "../../../types/models/Acknowledgement";
import { Fonts } from "../../../constants/AppEnums";
import { AppState } from "../../../redux/store";
import { RuleUnitState } from "../../../types/models/RuleUnitState";
import { TeamMember, TeamMemberProgress } from "../../../types/models/TeamMember";
import { getRuleInstanceMetadata } from "../../../utils/RuleUtil";
import AppDialog from "../../../components/AppBase/AppDialog";
import MemberAlert from "./MemberAlert";
import Chat from "../../../components/Chat";
import EmptyResult from "../../rules/EmptyResult";
import { VirtualTeam } from "../../../types/models/Team";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RoleState } from "../../../types/models/RoleState";
import "../MemberTable/MemberTableStyle.scss";
import { AlertMetaDataInstance } from "../../../types/models/AlertMetaData";
import { displayUserRole } from "../../../utils/StringUtils";
import ProgressPieChart from "../../../components/TableItems/ProgressPieChart";

interface MemberModalProps {
    open: boolean;
    onClose: () => void;
    user: TeamMember;
    userProgress: Acknowledgment[];
    selectedMember: TeamMemberProgress | undefined;
    setSelectedMember: (selectedMember: TeamMemberProgress) => void;
    currentTeam: VirtualTeam | undefined;
    alerts: AlertMetaDataInstance;
}

const MemberModal = ({ open, onClose, user, userProgress, selectedMember, setSelectedMember, currentTeam, alerts }: MemberModalProps) => {
    const { user_id, rule_id } = useParams();
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const roleState = useSelector<AppState, RoleState>((state) => state.role);
    const alertDetailMap = new Map(ruleUnitState.baseAlertRules?.concat(ruleUnitState.baseAlertHistories ?? []).map(rule => [rule.RuleInstanceId, rule]));
    const [openChatting, setOpenChatting] = useState<boolean>(false);
    const [selectedRuleInstanceId, setSelectedRuleInstanceId] = useState<string | undefined>();
    const [resolvedValue, setResolvedValue] = useState<number>(0);
    const handleResolvedChange = (event: React.SyntheticEvent, newValue: number) => {
        setResolvedValue(newValue);
    }

    const removeDuplicateAlerts = (alerts: Acknowledgment[]) => {
        const uniqueRuleIds = new Set<string>();
        return alerts.filter((alert) => {
            if(uniqueRuleIds.has(alert.RuleInstanceId)) {
                return false;
            }
            uniqueRuleIds.add(alert.RuleInstanceId);
            return true;
        })
    }

    const getUnreadAlert = (progress: Acknowledgment) => {
        if(!progress) {
            return null;
        }

        if(!progress.Resolved) {
            if(progress.SenderId !== null) {
                if(progress.SenderId === progress.UserId) {
                    return true;
                }
            }
            else if(progress.SenderId === null && progress.DisplayMessageCount === 1) {
                return true;
            }
        }
        else {
            return false;
        }
    }

    const getSortedUserProgress = useCallback(() => {
        const sortedUniqueAlerts = removeDuplicateAlerts(userProgress).sort((a, b) => {
            const detailA = alertDetailMap.get(a.RuleInstanceId);
            const detailB = alertDetailMap.get(b.RuleInstanceId);
            if (detailA && detailB) {
                const prefixA = getRuleInstanceMetadata(detailA.Metadata ?? "")?.prefix;
                const prefixB = getRuleInstanceMetadata(detailB.Metadata ?? "")?.prefix;
                if(detailA.CreatedAt !== null && detailB.CreatedAt !== null) {
                    return new Date(detailB.CreatedAt ?? "").getTime() - new Date(detailA.CreatedAt ?? "").getTime();
                }
                if (prefixA && prefixB) {
                    if (prefixA[0] === prefixB[0])
                        return detailA.DisplayName > detailB.DisplayName ? 1 : -1;
                    else
                        return (Number.parseInt(prefixA[0]) < Number.parseInt(prefixB[0])) ? -1 : 1;
                }
                else {
                    return detailA.DisplayName > detailB.DisplayName ? 1 : -1;
                }
            }
            return 0;
        });

        const unreadAlerts: Acknowledgment[] = [];
        const readAlerts: Acknowledgment[] = [];

        sortedUniqueAlerts.filter((alert) => {
            if(getUnreadAlert(alert)) {
                unreadAlerts.push(alert);
            }
            else {
                readAlerts.push(alert);
            }
        });

        return [...unreadAlerts, ...readAlerts];
    }, [userProgress, alertDetailMap]);

    useEffect(() => {
        // Open chatting window if the rule instance id is specified in URL
        if (user_id && rule_id && user_id == user.user_id) {
            setSelectedRuleInstanceId(rule_id);
            setOpenChatting(true);
        }
    }, [user]);

    const getLocationName = (locationId: string) => {
        const knownLocations = roleState.knownRoles?.locations;

        const location = knownLocations?.find((location) => location.LNIId === locationId);


        return location ? `${location?.LNIId} - ${location?.Name}` : locationId;
    }

    const createRuleProgressPieChart = (member: TeamMemberProgress) => {
        if ((member.progress?.length ?? 0) === 0 || member.completion?.total === undefined || member.completion.completed === undefined) {
            // Unknown person who is in Team but not in Organisation
            return (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Box sx={{ display: "flex", height: "50px", width: "50px", justifyContent: "center", alignItems: "center" }}>
                        <QuestionMarkIcon sx={{ mt: 0, height: "32px", width: "46px", color: "lightgray" }} />
                    </Box>
                </Box>
            );
        }
        else {
            const notAcknowledgedTotal = (member.completion?.total ?? 0) - (member.completion?.completed ?? 0);
            return (
                <Box sx={{position: "relative"}}>
                    <ProgressPieChart total={member.completion?.total ?? 0} completed={member.completion?.completed ?? 0} modal />
                    {notAcknowledgedTotal > 0 ?
                        <Typography variant="h4" sx={{ position: "absolute", left: "46.5%", top: 50, color: "#747474" }}>{notAcknowledgedTotal}</Typography>
                    : null}
                </Box>
            );
        }
    };

    return (
        <>
            <AppDialog
                open={open}
                onClose={onClose}
                dividers
                fullHeight
                title={
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                        }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                mb: 1,
                                p: 0,
                            }}
                        >
                            <Typography sx={{ fontWeight: Fonts.SEMI_BOLD, fontSize: "24" }}>
                                {user.name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "0.8rem",
                                    color: theme.palette.grey[600],
                                }}>
                                {user.email}
                            </Typography>
                            {user.roles !== null && user.roles.length > 0 ? (
                            <Accordion elevation={0} sx={{
                                m: 0,
                                p: 0,
                                width: "100%", 
                                boxShadow: "none", 
                                borderRadius: "0px",                                     
                                fontWeight: 600,
                                fontSize: "0.8rem",
                                color: theme.palette.grey[600] }}>
                                <AccordionSummary sx={{ m: 0, p: 0 }}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    Roles
                                </AccordionSummary>
                                    <AccordionDetails className="accordion-values" sx={{ m: 0, p: 0 }}>
                                        {user.roles?.map((role) => (
                                            <Tooltip key={role} title={displayUserRole(role, roleState.knownRoles?.roles)} placement="top">
                                                <Chip sx={{ m: 0.5 }} label={displayUserRole(role, roleState.knownRoles?.roles)} size="small" />
                                            </Tooltip>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ) : <Typography sx={{
                                fontWeight: 600,
                                fontSize: "0.8rem",
                                color: theme.palette.grey[600],
                            }}>User has no roles assigned</Typography>}

                            {user.locations !== null && user.locations.length > 0 ? (
                            <Accordion elevation={0} sx={{
                                m: 0, p: 0, 
                                width: "100%",
                                boxShadow: "none", 
                                borderRadius: "0px",                                     
                                fontWeight: 600,
                                fontSize: "0.8rem",
                                color: theme.palette.grey[600], }}>
                                <AccordionSummary sx={{ m: 0, p: 0, }}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    Locations
                                </AccordionSummary>
                                    <AccordionDetails className="accordion-values-location" sx={{ m: 0, p: 0 }}>
                                        {user.locations?.map((location) => (
                                            <Tooltip key={location} title={getLocationName(location ?? "")} placement="top">
                                                <Chip sx={{ m: 0.5, p: 1 }} label={getLocationName(location ?? "")} size="small" />
                                            </Tooltip>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ) : <Typography sx={{
                                fontWeight: 600,
                                fontSize: "0.8rem",
                                color: theme.palette.grey[600],
                            }}>User has no locations assigned</Typography>}
                        </Box>
                    </Box>
                }>
                <Box sx={{ my: 5 }}>
                {selectedMember ? createRuleProgressPieChart(selectedMember) : null}
                </Box>
                <Box
                    sx={{
                        overflowY: "auto",
                        mx: 2,
                        mb: 1,
                        p: 1,
                        borderRadius: "10px",
                    }}>
                    {(!userProgress || userProgress.length === 0) ? (
                        <EmptyResult>There are currently no alerts assigned</EmptyResult>
                    ) : (
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mx: 2, mb: 2 }}>
                                <Tabs value={resolvedValue} onChange={handleResolvedChange}>
                                    <Tab sx={{ textTransform: "none" }} label="Unresolved" />
                                    <Tab sx={{ textTransform: "none" }} label="Acknowledged" />
                                </Tabs>
                            </Box>
                            {userProgress.filter((progress) => progress.Resolved === (resolvedValue === 1)).length > 0 ?                                 
                            <Box>
                                {getSortedUserProgress().filter((progress) => progress.Resolved === (resolvedValue === 1)).map((progress) => (
                                    <MemberAlert
                                        key={progress.RuleInstanceId}
                                        progress={progress}
                                        ruleInstance={alertDetailMap.get(progress.RuleInstanceId)}
                                        openAlertChat={(ruleInstanceId) => {
                                        setSelectedRuleInstanceId(ruleInstanceId);
                                        setOpenChatting(true);
                                        }}
                                        alerts={alerts}
                                        getUnreadAlert={getUnreadAlert}
                                    />
                                ))}
                            </Box> 
                                : 
                            <EmptyResult>{resolvedValue === 0 
                                ? 
                                "There are currently no unresolved alerts" 
                                : 
                                "There are currently no acknowledged alerts"}
                            </EmptyResult>}
                        </Box>
                    )}
                </Box>
            </AppDialog>
            {selectedRuleInstanceId &&
                <AppDialog
                    open={openChatting}
                    dividers
                    onClose={() => { setOpenChatting(false) }}
                    title={
                        <Box sx={{ display: "flex", width: "100%" }}>
                            <MessageIcon sx={{ mr: 1, mt: "2px", pl: 0 }} />
                            <Typography
                                component="h6"
                                sx={{
                                    mb: 1,
                                    fontWeight: Fonts.SEMI_BOLD,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                {user.name}
                            </Typography>
                            <Typography
                                sx={{
                                    mb: 1,
                                    ml: 1,
                                    fontWeight: Fonts.REGULAR,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                {alertDetailMap.get(selectedRuleInstanceId)?.DisplayName}
                            </Typography>
                        </Box>}>
                    <Chat
                        ruleInstanceId={selectedRuleInstanceId}
                        subordinateId={user.user_id ?? ""}
                        responseMode={ruleUnitState.userShieldId !== user.user_id} // If the login user is selected, open with sending mode
                        userProgress={userProgress}
                        selectedMember={selectedMember}
                        setSelectedMember={setSelectedMember}
                        enableEscalation={true}
                        currentTeam={currentTeam}
                    />
                </AppDialog>
            }
        </>
    );
};
export default MemberModal;
